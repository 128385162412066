import React, { FC } from 'react';
import { IPublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { useAccount } from '@azure/msal-react';
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import {
    ClientType,
    HeaderProvider,
    HowToProvider,
    LockProvider,
    NavigationProvider,
    NotificationsProvider,
    OutcomesProvider,
    ScrollToTop,
    ShowcaseProvider,
    TokenProvider,
    UserProvider,
    DialogProvider,
    ApiServiceProvider,
    SnackbarProvider,
    InsightsProvider,
    SkillsProvider,
    HomeProvider,
    ErrorBoundary,
} from 'common-v2';

interface IProps {
    instance: IPublicClientApplication;
    accounts: Partial<Pick<AccountInfo, 'homeAccountId' | 'localAccountId' | 'username'>>[];
}

const Token: FC<IProps> = ({ instance, accounts }) => {
    const account = useAccount(accounts[0]);

    return (
        <TokenProvider
            propOid={account?.localAccountId ?? ''}
            prodTid={account?.tenantId ?? ''}
            propClientType={ClientType.platform}
        >
            <BrowserRouter>
                <ErrorBoundary>
                    <UserProvider>
                        <HomeProvider>
                            <SkillsProvider>
                                <OutcomesProvider>
                                    <ShowcaseProvider>
                                        <InsightsProvider>
                                            <NotificationsProvider>
                                                <HowToProvider>
                                                    <SnackbarProvider>
                                                        <ApiServiceProvider>
                                                            <DialogProvider>
                                                                <NavigationProvider>
                                                                    <HeaderProvider clientType={ClientType.platform}>
                                                                        <LockProvider>
                                                                            <ScrollToTop />
                                                                            <Routes />
                                                                        </LockProvider>
                                                                    </HeaderProvider>
                                                                </NavigationProvider>
                                                            </DialogProvider>
                                                        </ApiServiceProvider>
                                                    </SnackbarProvider>
                                                </HowToProvider>
                                            </NotificationsProvider>
                                        </InsightsProvider>
                                    </ShowcaseProvider>
                                </OutcomesProvider>
                            </SkillsProvider>
                        </HomeProvider>
                    </UserProvider>
                </ErrorBoundary>
            </BrowserRouter>
        </TokenProvider>
    );
};

export default Token;
