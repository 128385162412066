import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { isAfter } from 'date-fns';
import { useAccount, useMsal } from '@azure/msal-react';
import {
    BulkAssignOutcomes,
    ClientType,
    hasAdminRole,
    HistoryDetails,
    Home,
    HowTo,
    HowToCategory,
    HowToPlaylist,
    InsightOutcomeDetail,
    Insights,
    InsightsDetail,
    Instructions,
    LicenseClass,
    LicenseType,
    Loading,
    NoAccount,
    NoNulia,
    Notifications,
    OutcomeDetails,
    Outcomes,
    Pages,
    Preferences,
    Showcase,
    SkillDetails,
    Skills,
    TokenContext,
    TokenExpired,
    useGetUser,
    useGetUserId,
    UserContext,
    YourAchievements,
    YourBadges,
} from 'common-v2';

interface IProps {}

const Routes: FC<IProps> = () => {
    const { user, noNulia } = useContext(UserContext);
    const { expire } = useContext(TokenContext);
    const [didExpire, setDidExpire] = useState<boolean>(false);
    const [receivedData, setReceivedData] = useState<boolean>(false);
    const getUser = useGetUser();
    const { accounts } = useMsal();
    const account = useAccount(accounts[0]);
    const getUserId = useGetUserId();

    useEffect(() => {
        getUserId(account);
    }, [account, getUserId]);

    useEffect(() => {
        if (!receivedData) {
            getUser();
            setReceivedData(true);
        }
    }, [receivedData, getUser]);

    useEffect(() => {
        if (expire && isAfter(new Date(), expire)) {
            setDidExpire(true);
        }
    }, [expire]);

    if (didExpire) {
        return <TokenExpired />;
    }

    if (noNulia) {
        return <NoNulia />;
    }

    if (!user) {
        return <Loading />;
    }

    if (user.licenseType === LicenseType.None && hasAdminRole(user)) {
        return (
            <Switch>
                <Route exact={true} path="/assessments">
                    <Insights page={Pages.None} />
                </Route>
                <Route exact={true} path="/user-insights">
                    <Insights page={Pages.None} />
                </Route>
                <Route exact={true} path="/insights-detail/:oid">
                    <InsightsDetail />
                </Route>
                {/* <Route exact={true} path="/outcome-insights">
                    <Insights page={Pages.None} />
                </Route> */}
                <Route exact={true} path="/insights-outcome-detail/:outcomeId">
                    <InsightOutcomeDetail page={Pages.None} />
                </Route>
                <Route exact={true} path="/licenses-insights">
                    <Insights page={Pages.None} />
                </Route>
                <Route exact={true} path="/history-insights">
                    <Insights page={Pages.None} />
                </Route>
                <Route exact={true} path="/insights-history-details/:id">
                    <HistoryDetails page={Pages.None} />
                </Route>
                <Route exact={true} path="/bulk">
                    <BulkAssignOutcomes page={Pages.None} />
                </Route>
                <Route exact={true} path="/notifications">
                    <Notifications clientType={ClientType.platform} />
                </Route>
                <Route exact={true} path="/preferences">
                    <Preferences page={Pages.None} />
                </Route>
                <Redirect from="*" to="/assessments" />
            </Switch>
        );
    } else if (user.licenseType === LicenseType.None && user.licenseClass === LicenseClass.Complete) {
        return <NoAccount />;
    } else if (user.licenseType === LicenseType.None && user.licenseClass === LicenseClass.Unknown) {
        return <NoNulia />;
    }

    return (
        <Switch>
            <Route exact={true} path="/">
                <Home page={Pages.None} clientType={ClientType.platform} />
            </Route>
            <Route exact={true} path="/your-skills">
                <Skills page={Pages.None} />
            </Route>
            <Route exact={true} path="/all-skills">
                <Skills page={Pages.None} />
            </Route>
            <Route exact={true} path="/skill-details/:skillId">
                <SkillDetails clientType={ClientType.platform} />
            </Route>
            <Route exact={true} path="/your-outcomes">
                <Outcomes page={Pages.None} />
            </Route>
            <Route exact={true} path="/all-outcomes">
                <Outcomes page={Pages.None} />
            </Route>
            <Route exact={true} path="/outcome-details/:outcomeId">
                <OutcomeDetails page={Pages.None} />
            </Route>
            <Route exact={true} path="/showcase">
                <Showcase page={Pages.None} />
            </Route>
            <Route exact={true} path="/your-badges">
                <YourBadges />
            </Route>
            <Route exact={true} path="/your-achievements">
                <YourAchievements />
            </Route>
            <Route exact={true} path="/instructions/:assertionId">
                <Instructions />
            </Route>
            <Route exact={true} path="/user-insights">
                <Insights page={Pages.None} />
            </Route>
            <Route exact={true} path="/insights-detail/:oid">
                <InsightsDetail />
            </Route>
            {/* <Route exact={true} path="/outcome-insights">
                <Insights page={Pages.None} />
            </Route> */}
            <Route exact={true} path="/insights-outcome-detail/:outcomeId">
                <InsightOutcomeDetail page={Pages.None} />
            </Route>
            <Route exact={true} path="/licenses-insights">
                <Insights page={Pages.None} />
            </Route>
            <Route exact={true} path="/history-insights">
                <Insights page={Pages.None} />
            </Route>
            <Route exact={true} path="/insights-history-details/:id">
                <HistoryDetails page={Pages.None} />
            </Route>
            <Route exact={true} path="/bulk">
                <BulkAssignOutcomes page={Pages.None} />
            </Route>
            <Route exact={true} path="/notifications">
                <Notifications clientType={ClientType.platform} />
            </Route>
            <Route exact={true} path="/preferences">
                <Preferences page={Pages.None} />
            </Route>
            <Route exact={true} path="/how-to">
                <HowTo page={Pages.None} />
            </Route>
            <Route exact={true} path="/how-to/:categoryId">
                <HowToCategory page={Pages.None} />
            </Route>
            <Route exact={true} path="/how-to-playlist/:playlistId/:playlistTitle/:categoryName/:categoryId">
                <HowToPlaylist page={Pages.None} />
            </Route>
            <Route exact={true} path="/assessments">
                <Insights page={Pages.None} />
            </Route>
            <Redirect from="*" to="/" />
        </Switch>
    );
};

export default Routes;
