import React from 'react';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Analytics, ClientType, Loading, Login, msalConfig, NuliaTheme } from 'common-v2';
import Token from './Token';

const MainContent = () => {
    const { instance, accounts, inProgress } = useMsal();

    if (inProgress === 'login') {
        return <Loading />;
    }

    return (
        <div>
            <AuthenticatedTemplate>
                <Token instance={instance} accounts={accounts} />
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>
        </div>
    );
};

export default function App() {
    const msalInstance = new PublicClientApplication(msalConfig);

    return (
        <MsalProvider instance={msalInstance}>
            <NuliaTheme clientType={ClientType.platform}>
                <Analytics clientType={ClientType.platform}>
                    <MainContent />
                </Analytics>
            </NuliaTheme>
        </MsalProvider>
    );
}
